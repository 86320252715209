import React from 'react'

import Banner from '../components/banner'
import ThankYou from '../components/thank-you'
import IconsRow from '../components/icons-row'
import Carousel from '../components/carousel'
import Items from '../components/items'
import Testimonials from '../components/testimonials'
import LeadForm from '../components/lead-form'
import DownloadForm from '../components/download-form'
import PricingTable from '../components/pricing-table'
import Timeline from '../components/timeline'
import Video from '../components/video'

const renderBlock = (param, el, i, download) => {
  let block = {
    'WordPressAcf_banner' : (el, i) => <Banner key={i} {...el} />,
    'WordPressAcf_thank_you' : (el, i) => <ThankYou download={download} key={i} {...el} />,
    'WordPressAcf_icons' : (el, i) => <IconsRow key={i} {...el} />,
    'WordPressAcf_carousel' : (el, i) => <Carousel key={i} {...el} />,
    'WordPressAcf_items' : (el, i) => <Items key={i} {...el} />,
    'WordPressAcf_testimonials' : (el, i) => <Testimonials key={i} {...el} />,
    'WordPressAcf_lead_form' : (el, i) => <LeadForm key={i} {...el} />,
    'WordPressAcf_download_form' : (el, i) => <DownloadForm key={i} {...el} />,
    'WordPressAcf_pricing_table' : (el, i) => <PricingTable key={i} {...el} />,
    'WordPressAcf_timeline' : (el, i) => <Timeline key={i} {...el} />,
    'WordPressAcf_video' : (el, i) => <Video key={i} {...el} />,
  }[param]

  if (!block) return

  return block(el, i)
}

export default renderBlock
