import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ThankYou extends Component {

  render() {

    let props = this.props

    return (
      <section className='banner banner--thanks' style={{backgroundImage: `url(${props.background_image.localFile.childImageSharp.original.src})`}}>
        <div className='banner__inner'>
          <Fade bottom distance='30px'>
            <div className='banner__content'>
              <h2>{props.title}</h2>
              <p>{props.subtitle}</p>
              { props.download.state && props.download.state.download &&
                <a href={props.download.state.download} className='btn' target='_blank' rel='noopener noreferrer'>Download Brochure</a>
              }
            </div>
          </Fade>
        </div>
      </section>
    )

  }

}

export default ThankYou