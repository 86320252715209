import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class Items extends Component {
  render() {
    const props = this.props
    return (
      <section className='items'>
        <Fade cascade bottom distance='30px'>
        <div className='items__inner'>
          { props.title &&
            <h2>{ props.title }</h2>
          }
          { props.subtitle &&
            <p>{ props.subtitle }</p>
          }
          { props.items &&
            <div className='items__items'>
              { props.items.map((el, i) => {
                return ( 
                  <div className='items__item' key={i}>
                    <img src={el.image.localFile.childImageSharp.original.src} alt={el.alt_text} />
                    <h4>{el.title}</h4>
                  </div>
                )
              }) }
            </div>
          }
        </div>
        </Fade>
      </section>
    )
  }
}

export default Items