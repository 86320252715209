import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import { Link } from 'gatsby'

import { LongArrow } from './icons'

class Banner extends Component {

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({ 
      behavior: 'smooth' 
    })
  }

  render() {

    let props = this.props

    return (
      <section className='banner' style={{backgroundImage: `url(${props.background_image && props.background_image.localFile.childImageSharp.original.src})`}}>
        <div className='banner__inner'>
          <div className='banner__content'>
            <Fade bottom distance='30px'>
              <h1>{props.title}</h1>
              <p>{props.subtitle}</p>
            </Fade>
          </div>
          <div className='banner__quad'>
            {this.props.quad && this.props.quad.map((el, i) => (
              <div className='banner__item' key={i}>
                <div className='banner__wrap'>
                  <h4>{el.title}</h4>
                  <p>{el.content}</p>
                  <LongArrow color='#006CB4' />
                </div>
                { !el.link.includes('/') &&
                  <span className='banner__link' onClick={() => this._scrollTo(el.link)}></span>
                }
                { el.link.includes('/') &&
                  <Link className='banner__link' to={el.link} />
                }
              </div>
            ))}
          </div>
        </div>
      </section>
    )

  }

}

export default Banner