import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import { Play } from '../components/icons'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class Video extends Component {

  state = {
    show: false,
  }

  showModal = () => {
    this.setState({ show: true })
    let src = document.querySelector('.video-wrapper iframe').getAttribute('src')
    src += '&autoplay=1'
    document.querySelector('.video-wrapper iframe').setAttribute('src', src)
  }

  hideModal = () => {
    this.setState({ show: false })
    let src = document.querySelector('.video-wrapper iframe').getAttribute('src')
    let stopVideoSrc = src.replace('&autoplay=1', '')
    document.querySelector('.video-wrapper iframe').setAttribute('src', stopVideoSrc)
  }

  render() {

    let props = this.props

    return (
      <>
        <section className='video'>
          <div className='video__inner'>
            <Fade bottom distance='30px'>
  		        { props.title &&
                <h2>{ props.title }</h2>
              }
              { props.subtitle &&
                <p>{ props.subtitle }</p>
              }
      		  </Fade>
      		  <Fade bottom distance='30px'>
  	          <div className="video__image">
	              <img src={props.video.image.localFile.childImageSharp.original.src} alt={props.title} />
	              <div className='video__play' onClick={() => this.showModal()}>
			            <Play color={'#FFF'} />
			          </div>
  	          </div>
            </Fade>
          </div>
        </section>
        { props.video.video &&
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <div className="video-wrapper" dangerouslySetInnerHTML={{ __html: props.video.video }} />
          </Modal>
        }
      </>
    )
  }
}

export default Video
