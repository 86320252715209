import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {
  render() {
    let page = this.props.data.wordpressPage
    let download = this.props.location
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass={'page-template '+ page.slug} description={page.yoast_meta.yoast_wpseo_metadesc} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i, download)
        }) }
      </>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: {eq: $id}) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_banner {
            background_image {
              ...original
            }
            title
            subtitle
            quad {
              title
              content
              link
            }
          }
          ... on WordPressAcf_lead_form {
            title
            content
            button {
              title
              link
            }
            form {
              title
              course_tag
            }
          }
          ... on WordPressAcf_timeline {
            title
            subtitle
            timeline {
              image {
                ...original
              }
              name
              content
            }
          }
          ... on WordPressAcf_icons {
            items {
              icon
              title
              content
              link
            }
          }
          ... on WordPressAcf_video {
            title
            subtitle
            video {
              image {
                ...original
              }
              video
            }
          }
          ... on WordPressAcf_download_form {
            image {
              ...original
            }
            label
            form {
              title
              subtitle
              form_text
            }
            download_file
            course_tag
          }
          ... on WordPressAcf_thank_you {
            background_image {
              ...original
            }
            title
            subtitle
            download_link
          }
        }
      }
    }
  }
`
