import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class DownloadForm extends Component {

  state = {
    pageUrl: '',
    courseTag: '',
    download: this.props.download_file
  }

  componentDidMount() {
    this.setState({ 
      pageUrl: window.location.href,
      courseTag: this.props.course_tag
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ ['course_tag']: this.props.course_tag });
    this.setState({ ['page_url']: window && window.location.href });
  }

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      window && window.gtag && window.gtag('event', 'generate_lead', {
        'event_label': 'Brochure Download',
        'event_category' : 'submit_form'
      });
      navigate('/brochure-thank-you', { 
        state: {
          download: this.state.download
        }
      })
    })
    .catch(error => this.setState({result: 'fail', msg: error}));
  }

  render() {
    const props = this.props
    return (
      <section className='download-form'>
        <Fade cascade bottom distance='30px'>
        <div className='download-form__inner'>
          <div className='download-form__content'>
            <div className='download-form__image'>
              { props.label && <span className='download-form__label'>{ props.label }</span> }
              <img src={props.image.localFile.childImageSharp.original.src} alt={props.image.alt_text} />
            </div>
          </div>
          <div className='download-form__form'>
            <form
              className='form'
              name="download_form"
              method='post'
              data-netlify='true'
              data-netlify-recaptcha='true'
              onSubmit={this.handleSubmit}
            >
              <h3>{ props.form.title }</h3>
              <p>{ props.form.form_text }</p>
              <input type='hidden' name='form-name' value='download_form' />
              <div className='form__row form__row--double'>
                <input type='text' name='first_name' id='first_name' placeholder='First Name*' onChange={this.handleChange} required />
                <input type='text' name='last_name' id='last_name' placeholder='Last Name*' onChange={this.handleChange} required />
              </div>
              <div className='form__row'>
                <input type='email' name='email' id='email' onChange={this.handleChange} placeholder='Email Address*' required />
              </div>
              <input type='text' name='page_url' id='page_url' value={this.state.pageUrl} onChange={this.handleChange} />
              <input type='text' name='course_tag' className='course-tag' value={this.state.courseTag} onChange={this.handleChange} />
              <div className='form__row'>
                <Recaptcha
                  ref='recaptcha'
                  sitekey={RECAPTCHA_KEY}
                  onChange={this.handleRecaptcha}
                />
              </div>
              <div className='form__row form__row--submit'>
                <button className='btn' type='submit'>Download your guide</button>
              </div>
            </form>
          </div>
        </div>
        </Fade>
      </section>
    )
  }
}

export default DownloadForm