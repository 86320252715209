import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'

import RenderIcon from '../utils/render-icon'

import Recaptcha from 'react-google-recaptcha'

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class LeadForm extends Component {

  state = {
    pageUrl: '',
    courseTag: '',
  }

  componentDidMount() {
    this.setState({ 
      pageUrl: window.location.href,
      courseTag: this.props.form.course_tag
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ ['course_tag']: this.props.form.course_tag });
    this.setState({ ['page_url']: window && window.location.href });
  }

  handleRecaptcha = value => {
    this.setState({ "g-recaptcha-response": value });
  }

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({ 
      behavior: 'smooth' 
    })
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
    .then(() => {
      window && window.gtag && window.gtag('event', 'generate_lead', {
        'event_label' : 'Lead Form',
        'event_category' : 'submit_form'
      });
      navigate('/contact-thank-you')
    })
    .catch(error => this.setState({result: 'fail', msg: error}));
  }

  render() {
    const props = this.props
    return (
      <section className='lead-form'>
        <div className='lead-form__left'>
          <Fade cascade bottom distance='30px'>
            <div className='lead-form__content'>
              { props.title &&
                <h2>{ props.title }</h2>
              }
              { props.content &&
                <div dangerouslySetInnerHTML={{ __html:props.content }} />
              }
              <span className='btn' onClick={() => this._scrollTo('.download-form__form')}>
                { RenderIcon(props.button.icon) }
                <span className='btn__text'>
                  <span className='btn__title'>{props.button.title}</span>
                </span>
              </span>
            </div>
          </Fade>
        </div>
        <div className='lead-form__right'>
          <Fade cascade bottom distance='30px'>
            <div className='lead-form__form'>
              <h3>{ props.form.title }</h3>
              <p>{ props.form.subtitle }</p>
              <form
                className='form'
                name="lead_form"
                method='post'
                data-netlify='true'
                data-netlify-recaptcha='true'
                id='free-quote'
                onSubmit={this.handleSubmit}
              >
                <input type='hidden' name='form-name' value='lead_form' />
                <div className='form__row form__row--double'>
                  <input type='text' name='first_name' id='first_name' placeholder='First Name*' onChange={this.handleChange} required />
                  <input type='text' name='last_name' id='last_name' placeholder='Last Name*' onChange={this.handleChange} required />
                </div>
                <div className='form__row'>
                  <input type='email' name='email' id='email' onChange={this.handleChange} placeholder='Email Address*' required />
                </div>
                <div className='form__row'>
                  <input type='text' name='phone_number' id='phone_number' onChange={this.handleChange} placeholder='Phone Number*' required />
                </div>
                <div className='form__row'>
                  <textarea name='message' id='message' onChange={this.handleChange} placeholder='Message*' required />
                </div>
                <input type='text' name='page_url' id='page_url' value={this.state.pageUrl} onChange={this.handleChange} />
                <input type='text' name='course_tag' className='course-tag' value={this.state.courseTag} onChange={this.handleChange} />
                <div className='form__row'>
                  <Recaptcha
                    ref='recaptcha'
                    sitekey={RECAPTCHA_KEY}
                    onChange={this.handleRecaptcha}
                  />
                </div>
                <div className='form__row form__row--submit'>
                  <button className='btn' type='submit'>Register Now</button>
                </div>
              </form>
            </div>
          </Fade>
        </div>
      </section>
    )
  }
}

export default LeadForm